<template>
  <div>
    <div align="right" class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15vw">
        <v-card outlined class="transparent" align="center">
          <h1 class="ar" style="color: #2a3977">نبذة عنا</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <v-card outlined class="transparent"
          ><h2 class="boldm titlea ar" style="color: #2a9ae5; font-size: 2.5vw">
            قصتنا و تراثنا
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <v-card outlined class="transparent"
          ><h3
            class="subtitlem ar"
            style="
              color: #2a3977;
              font-size: 1.7187500000000002vw;
              font-weight: 1000;
            "
          >
            عندما يلتقي التراث مع التكنولوجيا الحديثة
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p class="ar" style="color: #204170">
            بدأت قصتنا منذ عام 1960 ، وهي السنة التي سجلت بداية أعمالنا كمزودين
            للرعاية الصحية بأسلوب راسخ بحيث (أوبتكوس شامي). ومنذ ذلك الحين تنامت
            شركة أصبحت فروعها منتشرة على 40 فرعًا داخل الأردن وخارجه.
            <br /><br />
            أما بالنسبة لمركز الشامي للعيون فقد تأسس عام 2008 تحت رعاية صاحبة
            السمو الملكي الأميرة سمية بنت الحسن. ويعمل مركز الشامي للعيون في
            ثلاثة مواقع منتشرة في جميع أنحاء المملكة وثلاثة مواقع أخرى في منطقة
            الشرق الأوسط وشمال أفريقيا. وبشكل جماعي، يتم إدارة عياداتنا بواسطة
            16 من أطباء العيون، و 17 من الأخصائيين في علوم البصريات و 145 من
            الأخصائيين في مجالات الرعاية الصحية، بالإضافة إلى موظفي الإدارة
            وموظفي الدعم اللوجستي والفني.
            <br /><br />
            من المؤكد أن علامة (الشامي) التجارية تمتاز بالسمعة الطيبة منذ أكثر
            من نصف قرن، نظراً لكونها توفر أعلى مستويات الجودة والدقة في مجال طب
            وجراحة العيون.
            <br /><br />
            إن نجاحنا المستمر وإنجازاتنا هي نتاج فريقنا المتميز الذي يقدم
            الرعاية الممتازة ذات الجودة العالية لكل مريض من مرضانا. فنحن نتشرف
            بأن نكون مزودين مميزين للرعاية الصحية، وأن مرضانا يحظون بأولوية
            اهتماماتنا.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm titlea ar" style="color: #2a9ae5; font-size: 2.5vw">
            من نحن؟
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170"
          ><p class="ar">
            تأسس مركز الشامي للعيون في عام 2008 وكان افتتاح المركز الرئيسي في
            عمان الأردن بالرعاية السامية لسمو الأميرة سمية بنت الحسن في الأول من
            يونيو للعام 2008 . ينتمي المركز إلى عائلة أوبتكوس شامي العريقة في
            مجال البصريات والتي تأسس أول فرع لها في مدينة إربد شمال الأردن في
            العام 1960 لتصل شبكتها إلى 38 فرعا في العام 2011 حيث تخدم زبائنها في
            كل من عمان والزرقاء وإربد والعقبة . <br />
            <br />
            يقدم المركز مجموعة متميزة من الكفاءات الطبية, واختصاصيون في مختلف
            تخصصات طب وجراحة العيون ؛ متمرسون في استخدام آخر ما توصل إليه العلم
            والتقنية من أجهزة ومعدات تشخيصية وجراحية. حيث تلتقي الخبرة العريقة
            مع التقنية الحديثة في أحد أكبر المراكز المتخصصة على مستوى المملكة و
            المنطقة على حد سواء.
            <br /><br />
            تتنوع خدمات المركز من فحص العيون الاعتيادي إلى إجراءات علاج العيون
            البسيطة مثل إزالة دهنيات العين وعلاج ظغط العين وغيرها وصولا إلى
            عمليات العيون المتقدمة مثل الليزك وعملية إزالة الماء الأبيض والقرنية
            المخروطية و تركيب حلقات القرنية. بالإضافة الى ذلك يقدم الشامي للعيون
            خدماته العلاجية للأطفال الذي يعانون من الحول ومشاكل العيون الأخرى.
            <br /><br />
            إن المركز مجهز بأحدث الأجهزة المتطورة والتي تضمن دقة التشخيص وذلك
            لكونها أكثر أمانا في اتخاذ القرار بخصوص اجراء العمليات حيث أن هنالك
            أشخاص لا تلائمهم عمليات تصحيح النظر أو أي عمليات أخرى وعلى غرار
            النتائج والقراءات التي نحصل عليها من هذه الفحوصات يتم اختيار البديل
            والعلاج الأفضل للمريض.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>

        <v-card outlined class="transparent"
          ><h2 class="boldm titlea ar" style="color: #2a9ae5; font-size: 2.5vw">
            ما نقوم به؟
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p class="ar">
            جهز "مركز الشامي للعيون" بأدوات التكنولوجيا التشخيصية والجراحية
            الأكثر حداثة ودقة في طب وجراحة العيون، ومن خلال توظيف المتخصصين من
            أطباء العيون وإخصائي البصريات، مما يضمن توفير أفضل رعاية ممكنة
            للمرضى من أجل النجاح في تشخيص وعلاج والوقاية من أمراض العيون.
            <br /><br />
            إن الرعاية الصحية للمرضى هي نواة الخدمة الصحية وهي أسمى غاياتنا؛
            انطلاقاً من ذلك نقوم بتطوير خدماتنا باستمرار لتلبية احتياجات مرضانا
            ومواكبة التقدم التكنولوجي، مما يضمن زيادة وصول المرضى الى الرعاية
            التي تمتاز بالجودة.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>
        <v-card outlined class="transparent"
          ><h3
            class="subtitlem ar"
            style="color: #293876; font-size: 1.875vw; font-weight: 1000"
          >
            <span>:</span>
            تشمل خدماتنا حاليًا
          </h3>
        </v-card>
        <v-card outlined tile class="transparent" height="2.1875vw"></v-card>
        <vue-list
          fontsize="1.7187500000000002vw"
          :items="mainitem1"
          color="#2A9AE5"
          :lh="18"
        ></vue-list>
        <v-card style="margin: 0 1.953125vw 0 0" outlined class="transparent">
          <vue-list
            :bullet="true"
            :items="items1"
            color="#293876 !important"
            :lh="18"
          ></vue-list>
        </v-card>
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <vue-list
          fontsize="1.7187500000000002vw"
          :items="mainitem2"
          color="#2A9AE5"
          :lh="18"
        ></vue-list>
        <v-card style="margin: 0 1.953125vw 0 0" outlined class="transparent">
          <vue-list
            :bullet="true"
            :items="items2"
            color="#293876 !important"
            :lh="18"
          ></vue-list>
        </v-card>
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <vue-list
          fontsize="1.7187500000000002vw"
          :items="mainitem3"
          color="#2A9AE5"
          :lh="18"
        ></vue-list>
        <v-card style="margin: 0 1.953125vw 0 0" outlined class="transparent">
          <vue-list
            :bullet="true"
            :items="items3"
            color="#293876 !important"
            :lh="18"
          ></vue-list>
        </v-card>
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <h2 class="boldm titlea ar" style="color: #2a9ae5; font-size: 2.5vw">
          السياحة العلاجية في الأردن
        </h2>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p class="ar">
            وفقاً لتقرير البنك الدولي في عام 2014، تم تصنيف السياحة العلاجية في
            الأردن على أنها في المركز الأول في منطقة الشرق الأوسط وشمال أفريقيا
            وفي المركز الخامس على مستوى العالم. الأردن هو البلد الوحيد في الشرق
            الأوسط الذي يولد دخلاً من قطاع الرعاية الصحية يزيد على إنفاقه، وهو
            يسير بفخر وبشكل متسارع نحو أعلى المراكز في مجال الرعاية الصحية.
            <br /><br />
            يمتاز الأردن أيضاً بامتلاكه لمناطق جذب طبيعية وثقافية للسياح، مما
            يزيد من ميزته كموقع للسياحة العلاجية، حيث يحتضن "البتراء"، التي
            توّجت مؤخراً من عجائب الدنيا السبع. كما أن الأردن هو موطن للبحر
            الميت، وهو أدنى نقطة على وجه الأرض، الذي يعتبر من أفضل المنتجعات
            الطبيعية والعلاجية في العالم.
            <br /><br />
            وتضمن مراكز التميز في رعاية العيون بالأردن، مثل الشامي للعيون، توفير
            معايير عالية الجودة في خدمات الرعاية الصحية التي تطبق قيماً أساسية
            محددة، هي: الجودة، وسهولة الوصول، وتوافر الخدمات، والقدرة على تحمل
            التكاليف.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="4.6875vw"></v-card>
      </div>
    </div>
    <!-- MOBILE START -->
    <div align="right" class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1
            class="art"
            style="color: #2a3977; font-size: 5.333333333333334vw"
          >
            نبذة عنا
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <v-card outlined class="transparent"
          ><h2 class="boldm titlear" style="color: #2a9ae5; margin: 0">
            قصتنا و تراثنا
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <v-card outlined class="transparent"
          ><h3 class="subtitlemm ar" style="color: #2a3977; margin: 0">
            عندما يلتقي التراث مع التكنولوجيا الحديثة
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p class="arp">
            بدأت قصتنا منذ عام 1960 ، وهي السنة التي سجلت بداية أعمالنا كمزودين
            للرعاية الصحية بأسلوب راسخ بحيث (أوبتكوس شامي). ومنذ ذلك الحين تنامت
            شركة أصبحت فروعها منتشرة على 40 فرعًا داخل الأردن وخارجه.
            <br /><br />
            أما بالنسبة لمركز الشامي للعيون فقد تأسس عام 2008 تحت رعاية صاحبة
            السمو الملكي الأميرة سمية بنت الحسن. ويعمل مركز الشامي للعيون في
            ثلاثة مواقع منتشرة في جميع أنحاء المملكة وثلاثة مواقع أخرى في منطقة
            الشرق الأوسط وشمال أفريقيا. وبشكل جماعي، يتم إدارة عياداتنا بواسطة
            16 من أطباء العيون، و 17 من الأخصائيين في علوم البصريات و 145 من
            الأخصائيين في مجالات الرعاية الصحية، بالإضافة إلى موظفي الإدارة
            وموظفي الدعم اللوجستي والفني.
            <br /><br />
            من المؤكد أن علامة (الشامي) التجارية تمتاز بالسمعة الطيبة منذ أكثر
            من نصف قرن، نظراً لكونها توفر أعلى مستويات الجودة والدقة في مجال طب
            وجراحة العيون.
            <br /><br />
            إن نجاحنا المستمر وإنجازاتنا هي نتاج فريقنا المتميز الذي يقدم
            الرعاية الممتازة ذات الجودة العالية لكل مريض من مرضانا. فنحن نتشرف
            بأن نكون مزودين مميزين للرعاية الصحية، وأن مرضانا يحظون بأولوية
            اهتماماتنا.
          </p></v-card
        >
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm titlear" style="color: #2a9ae5; margin: 0">
            من نحن؟
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170"
          ><p class="arp" style="font-size: 4.266666666666667vw">
            تأسس مركز الشامي للعيون في عام 2008 وكان افتتاح المركز الرئيسي في
            عمان الأردن بالرعاية السامية لسمو الأميرة سمية بنت الحسن في الأول من
            يونيو للعام 2008 . ينتمي المركز إلى عائلة أوبتكوس شامي العريقة في
            مجال البصريات والتي تأسس أول فرع لها في مدينة إربد شمال الأردن في
            العام 1960 لتصل شبكتها إلى 38 فرعا في العام 2011 حيث تخدم زبائنها في
            كل من عمان والزرقاء وإربد والعقبة . <br /><br />
            يقدم المركز مجموعة متميزة من الكفاءات الطبية, واختصاصيون في مختلف
            تخصصات طب وجراحة العيون ؛ متمرسون في استخدام آخر ما توصل إليه العلم
            والتقنية من أجهزة ومعدات تشخيصية وجراحية. حيث تلتقي الخبرة العريقة
            مع التقنية الحديثة في أحد أكبر المراكز المتخصصة على مستوى المملكة و
            المنطقة على حد سواء.
            <br /><br />
            تتنوع خدمات المركز من فحص العيون الاعتيادي إلى إجراءات علاج العيون
            البسيطة مثل إزالة دهنيات العين وعلاج ظغط العين وغيرها وصولا إلى
            عمليات العيون المتقدمة مثل الليزك وعملية إزالة الماء الأبيض والقرنية
            المخروطية و تركيب حلقات القرنية. بالإضافة الى ذلك يقدم الشامي للعيون
            خدماته العلاجية للأطفال الذي يعانون من الحول ومشاكل العيون الأخرى.
            <br /><br />
            إن المركز مجهز بأحدث الأجهزة المتطورة والتي تضمن دقة التشخيص وذلك
            لكونها أكثر أمانا في اتخاذ القرار بخصوص اجراء العمليات حيث أن هنالك
            أشخاص لا تلائمهم عمليات تصحيح النظر أو أي عمليات أخرى وعلى غرار
            النتائج والقراءات التي نحصل عليها من هذه الفحوصات يتم اختيار البديل
            والعلاج الأفضل للمريض.
          </p></v-card
        >
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>

        <v-card outlined class="transparent"
          ><h2 class="boldm titlear" style="color: #2a9ae5; font-size: 2.5vw">
            ما نقوم به؟
          </h2>
        </v-card>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p class="arp">
            جهز "مركز الشامي للعيون" بأدوات التكنولوجيا التشخيصية والجراحية
            الأكثر حداثة ودقة في طب وجراحة العيون، ومن خلال توظيف المتخصصين من
            أطباء العيون وإخصائي البصريات، مما يضمن توفير أفضل رعاية ممكنة
            للمرضى من أجل النجاح في تشخيص وعلاج والوقاية من أمراض العيون.
            <br /><br />
            إن الرعاية الصحية للمرضى هي نواة الخدمة الصحية وهي أسمى غاياتنا؛
            انطلاقاً من ذلك نقوم بتطوير خدماتنا باستمرار لتلبية احتياجات مرضانا
            ومواكبة التقدم التكنولوجي، مما يضمن زيادة وصول المرضى الى الرعاية
            التي تمتاز بالجودة.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>
        <v-card outlined class="transparent"
          ><h3
            class="subtitlemmar"
            style="color: #293876; font-size: 1.875vw; font-weight: 1000"
          >
            <span>:</span>
            تشمل خدماتنا حاليًا
          </h3>
        </v-card>
        <v-card outlined tile class="transparent" height="2.1875vw"></v-card>
        <vue-list
          fontsize="1.7187500000000002vw"
          :items="mainitem1"
          color="#2A9AE5"
          :lh="18"
        ></vue-list>
        <v-card style="margin: 0 1.953125vw 0 0" outlined class="transparent">
          <vue-list
            :bullet="true"
            :items="items1"
            color="#293876 !important"
            :lh="18"
          ></vue-list>
        </v-card>
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <vue-list
          fontsize="1.7187500000000002vw"
          :items="mainitem2"
          color="#2A9AE5"
          :lh="18"
        ></vue-list>
        <v-card style="margin: 0 1.953125vw 0 0" outlined class="transparent">
          <vue-list
            :bullet="true"
            :items="items2"
            color="#293876 !important"
            :lh="18"
          ></vue-list>
        </v-card>
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <vue-list
          fontsize="1.7187500000000002vw"
          :items="mainitem3"
          color="#2A9AE5"
          :lh="18"
        ></vue-list>
        <v-card style="margin: 0 1.953125vw 0 0" outlined class="transparent">
          <vue-list
            :bullet="true"
            :items="items3"
            color="#293876 !important"
            :lh="18"
          ></vue-list>
        </v-card>
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <h2 class="boldm titlear" style="color: #2a9ae5; font-size: 2.5vw">
          السياحة العلاجية في الأردن
        </h2>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p class="arp">
            وفقاً لتقرير البنك الدولي في عام 2014، تم تصنيف السياحة العلاجية في
            الأردن على أنها في المركز الأول في منطقة الشرق الأوسط وشمال أفريقيا
            وفي المركز الخامس على مستوى العالم. الأردن هو البلد الوحيد في الشرق
            الأوسط الذي يولد دخلاً من قطاع الرعاية الصحية يزيد على إنفاقه، وهو
            يسير بفخر وبشكل متسارع نحو أعلى المراكز في مجال الرعاية الصحية.
            <br /><br />
            يمتاز الأردن أيضاً بامتلاكه لمناطق جذب طبيعية وثقافية للسياح، مما
            يزيد من ميزته كموقع للسياحة العلاجية، حيث يحتضن "البتراء"، التي
            توّجت مؤخراً من عجائب الدنيا السبع. كما أن الأردن هو موطن للبحر
            الميت، وهو أدنى نقطة على وجه الأرض، الذي يعتبر من أفضل المنتجعات
            الطبيعية والعلاجية في العالم.
            <br /><br />
            وتضمن مراكز التميز في رعاية العيون بالأردن، مثل الشامي للعيون، توفير
            معايير عالية الجودة في خدمات الرعاية الصحية التي تطبق قيماً أساسية
            محددة، هي: الجودة، وسهولة الوصول، وتوافر الخدمات، والقدرة على تحمل
            التكاليف.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="4.6875vw"></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/aboutus/mask_group_2.png";
import mphoto from "../assets/aboutus/Group 451.png";
import c1 from "../assets/aboutus/Group 186.png";
import c2 from "../assets/aboutus/Group 187.png";
import c3 from "../assets/aboutus/Group 188.png";
import c4 from "../assets/aboutus/Group 189.png";
import c5 from "../assets/aboutus/Group 190.png";
import c6 from "../assets/aboutus/Group 191.png";
import c7 from "../assets/aboutus/Group 192.png";
import c8 from "../assets/aboutus/Group 193.png";

export default {
  metaInfo: {
    title: "الشامي العيون",
  },
  data() {
    return {
      photo,
      mphoto,
      mainitem1: [{ item: "الكشوفات والفحوصات" }],
      items1: [
        { item: "كشف عام" },
        { item: "كشف طوارئ" },
        { item: "فحص نظر" },
        { item: "تصوير طوبغرافي للقرنية ثلاثي الابعاد" },
        { item: "قياس سماكة القرنية" },
        { item: "قياس الساحة البصرية" },
        { item: "قياس قوة العدسة" },
        { item: "تصوير ملون للشبكية بصبغة الفلورسين" },
      ],
      mainitem2: [{ item: "الاجراءات والعمليات البسيطة" }],
      items2: [
        { item: "تنظيف محفظة العدسة بالليزر" },
        { item: "فتح مجرى في القزحية لتخفيف ضغط العين" },
        { item: "فتح مجرى في القزحية لتخفيف ضغط العين" },
        { item: "ليزر لشبكية العين" },
        { item: "حقن لفتح مجرى الدمع" },
        { item: "توسيع مجرى الدمع" },
        { item: "ازالة جسم غريب من القرنية" },
        { item: "ازالة جسم غريب من الملتحمة" },

        { item: "حقن تحت الملتحمة" },
        { item: "ابرة افاستين" },
        { item: "سدادات لمجرى الدمع" },
        { item: "ازالة خيوط جراحية" },
        { item: "ازالة خيوط جراحية من القرنية داخل غرفة العمليات" },
        { item: "ضماده عدسات لاصقة" },
      ],
      mainitem3: [{ item: "العمليات" }],
      items3: [
        { item: "عملية ازالة كرة مزروعة في العين" },
        { item: "اصلاح تهتك في الملتحمة" },
        { item: "اصلاح تهتك في القرنية" },
        { item: "ازالة ظفر بدون زراعة صلبة" },
        { item: "ازالة ظفر مع زراعة ظفر" },
        { item: "عملية فتح لزاوية العين جراحيا" },
        { item: "عملية عمل فتحة في القزحية جراحيا" },
        { item: "عملية زراعة صمام احمد " },
        { item: "عملية قص السائل الزجاجي" },
        { item: "عملية ازالة مواد داخل العين مثل السيلكون" },
        { item: "عملية ازالة الماء الابيض مع زرع عدسة جراحيا" },
        { item: "عملية زرع عدسة ثانوية" },
        { item: "عملية ازالة الماء الابيض بالفاكو مع عدسة عادية" },
        { item: "عملية ازالة الماء الابيض بالفاكو مع عدسة ممتازة" },
        { item: "عملية ازالة الماء الابيض بالفاكو مع عدسة متكيفة" },
        { item: "عملية ازالة عتامة من القرنية بواسطة الليزر" },
        { item: "عملية تصحيح النظر بواسطة الليزر" },
        { item: "عملية سوبر لتصحيح النظر بواسطة الليزر" },
        { item: "عملية مخصصه لتصحيح النظر بواسطة الليزر" },
        { item: "عملية تصحيح النظر بواسطة الليزك" },
        { item: "عملية سوبر لتصحيح النظر بواسطة الليزك" },
        { item: "عملية مخصصه لتصحيح النظر بواسطة الليزك" },
        { item: "عملية تثبيت للقرنية" },
        { item: "عملية زراعة حلقات في القرنية حلقة واحدة" },
        { item: "عملية زراعة حلقات في  القرنية حلقتين" },
      ],

      pics: [
        {
          img: c1,

          title: "Post Operation Unit",
          titlem: "Post Operation Unit",
        },
        {
          img: c2,

          title: "Pediatrics Ophthalmology Unit",
          titlem: "Pediatrics Ophthalmology Unit",
        },
        {
          img: c3,

          title: "Refractive Errors Correction Unit",
          titlem: "Refractive Errors <br> Correction Unit",
        },
        {
          img: c4,

          title: "Oculoplasty and Beauty Unit",
          titlem: "Oculoplasty and Beauty Unit",
        },
        {
          img: c5,

          title: "Cataract Operations Unit",
          titlem: "Cataract Operations Unit",
        },
        {
          img: c6,

          title: "Retina Unit",
          titlem: "Retina Unit",
        },
        {
          img: c7,

          title: "Cornea Unit",
          titlem: "Cornea Unit",
        },
        {
          img: c8,

          title: "Glaucoma and Eye Pressure Unit",
          titlem: "Glaucoma and Eye <br> Pressure Unit",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
