<template>
  <div>
    <div class="hidden-sm-and-down">
      <img-page :img="photo"></img-page>
      <div style="margin: 0 15vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977">About Us</h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <v-card outlined class="transparent"
          ><h2 class="boldm titlea" style="color: #2a9ae5">
            Our Story
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <v-card outlined class="transparent"
          ><h3 class="subtitlem" style="color: #2a3977">
            Where legacy meets modern technology
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p>
            Our story began in 1960, the year which signaled the start of our
            work as health care providers. Since then the well-established
            “Optikos Shami” grew and became spread over 40 branches inside and
            outside Jordan.
          </p>
          <p>
            As for “Shami Eye Center”, it was established in 2008 under the
            patronage of her royal highness Princess Sumaya Bint Al-Hassan.
            Shami Eye Center operates in three locations around the Kingdom and
            three other locations in the Middle East. Collectively, our clinics
            are run by 16 ophthalmologists, 17 optometrists and 145 other
            healthcare professionals, administrative and support staff.
          </p>
          <p>
            Shami Brand is well reputed for over half a century, for providing
            premium quality and accuracy in ophthalmic care. Our continued
            success and achievements are a tribute to our exceptional team who
            deliver excellent, high quality, personalized and compassionate care
            for each of our patients. While we are honored to be a prominent
            health care provider, it’s really our patients who are our top
            priority.
          </p></v-card
        >
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm titlea" style="color: #2a9ae5">
            Who We Are?
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170"
          ><p>
            We were established in 2008, and were officially inaugurated in
            Amman, Jordan under the royal patronage of HRH Princess Sumaya Bint
            El Hassan on the first of June, 2008.
          </p>
          <p>
            We are owned by the family of the well-established Optikos Shami in
            the field of optics. Optikos Shami opened its first branch in 1960,
            in the city of Irbid in northern Jordan; in 2011, the number of
            branches reached 38, serving customers all over Amman, Al Zarqa,
            Irbid and Aqaba.
          </p>
          <p>
            We are committed to using state-of-the-art technology to offer
            premium, quality care to our patients. And by combining modern
            technology with an expert staff, we were able to become one of the
            largest specialized centers in both the Kingdom and the region.
          </p></v-card
        >
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>
        <v-card outlined class="transparent"
          ><h3 class="subtitlem" style="color: #2a3977">
            We provide a wide range of clinical eye services, through the
            following divisions:
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="1.09375vw"></v-card>
        <div style="line-height: 1.640625vw">
          <p style="color: #2a9ae5">
            <v-icon color="#2A9AE5" class="mr-3" style="font-size: 0.9375vw"
              >mdi-square-rounded</v-icon
            >Vision Correction
          </p>
          <p style="color: #2a9ae5">
            <v-icon color="#2A9AE5" class="mr-3" style="font-size: 0.9375vw"
              >mdi-square-rounded</v-icon
            >Cataract Division
          </p>
          <p style="color: #2a9ae5">
            <v-icon color="#2A9AE5" class="mr-3" style="font-size: 0.9375vw"
              >mdi-square-rounded</v-icon
            >Retina Division
          </p>
          <p style="color: #2a9ae5">
            <v-icon color="#2A9AE5" class="mr-3" style="font-size: 0.9375vw"
              >mdi-square-rounded</v-icon
            >Cornea Divison
          </p>
          <p style="color: #2a9ae5">
            <v-icon color="#2A9AE5" class="mr-3" style="font-size: 0.9375vw"
              >mdi-square-rounded</v-icon
            >Pediatrics Ophthalmology Division
          </p>
          <p style="color: #2a9ae5">
            <v-icon color="#2A9AE5" class="mr-3" style="font-size: 0.9375vw"
              >mdi-square-rounded</v-icon
            >Ophthalmic Plastic Division
          </p>
          <p style="color: #2a9ae5">
            <v-icon color="#2A9AE5" class="mr-3" style="font-size: 0.9375vw"
              >mdi-square-rounded</v-icon
            >Post Operation Division
          </p>
        </div>
        <v-card
          outlined
          tile
          class="transparent"
          height="3.2031249999999996vw"
        ></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm titlea" style="color: #2a9ae5">What We Do?</h2>
        </v-card>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p>
            “Shami Eye Center” is equipped with the latest state of the art
            diagnostic and surgical technology in ophthalmology through
            recruiting professional and competent ophthalmologists and
            optometrists. This ensures providing the best possible patient care
            to diagnose, prevent and treat diseases.
          </p>
          <p>
            As part of our patient-centered mission, and in order to increase
            patient access to quality care, our services are constantly evolving
            to meet the needs of our patients and the advances in technology.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>
        <v-card outlined class="transparent"
          ><h3 class="subtitlem" style="color: #2a3977">
            They currently include:
          </h3>
        </v-card>
        <v-card outlined tile class="transparent" height="2.1875vw"></v-card>
        <v-card outlined class="transparent">
          <span v-for="(item, i) in pics" :key="i">
            <v-card outlined class="transparent" style="display: flex">
              <v-card outlined class="transparent">
                <v-img
                  height="4.7242187499999995vw"
                  width="4.7242187499999995vw"
                  :src="item.img"
                ></v-img>
              </v-card>
              <v-card
                outlined
                class="transparent"
                style="padding: 1.484375vw 0 0 2.328125vw"
              >
                <p style="color: #2a9ae5">{{ item.title }}</p>
              </v-card>
            </v-card>
            <v-card outlined class="transparent" height="1.5234375vw"></v-card>
          </span>
        </v-card>
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <h2 class="boldm titlea" style="color: #2a9ae5">
          Medical Tourism in Jordan
        </h2>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p>
            Medical tourism in Jordan is ranked by the World Bank report in 2014
            as the first in the Middle East and North Africa and the fifth
            worldwide.
          </p>
          <p>
            Jordan is the only country in the Middle East that generates more
            income than its expenditure in the healthcare industry, and it is
            proudly on the fast track to the top in the healthcare field Jordan
            also possesses natural and cultural attractions for tourists, which
            adds to its advantage as a medical tourism location, as it embraces
            “Petra” one of the recently crowned Seven Wonders of the World.
            Jordan is also home to the Dead Sea, the lowest point on earth,
            which is considered one of the best natural spas in the world.
          </p>
          <p>
            The centers of excellence in eye care in Jordan, like Shami Eye,
            ensure the provision of high quality standards in healthcare
            services that implement certain core values; quality, accessibility,
            availability and affordability.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="4.6875vw"></v-card>
      </div>
    </div>
    <!-- MOBILE START -->
    <div class="hidden-md-and-up">
      <mimg-page :img="mphoto"></mimg-page>
      <div style="margin: 0 10.666666666666668vw">
        <v-card outlined class="transparent" align="center">
          <h1 style="color: #2a3977; font-size: 5.333333333333334vw">
            About Us
          </h1></v-card
        >
        <v-card outlined tile class="transparent" height="2.578125vw"></v-card>

        <v-card outlined class="transparent"
          ><h2 class="boldm titleam" style="color: #2a9ae5; margin: 0">
            Our Story
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <v-card outlined class="transparent"
          ><h3 class="subtitlemm" style="color: #2a3977; margin: 0">
            Where legacy meets modern technology
          </h3></v-card
        >
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p style="font-size: 4.266666666666667vw">
            Our story began in 1960, the year which signaled the start of our
            work as health care providers. Since then the well-established
            “Optikos Shami” grew and became spread over 40 branches inside and
            outside Jordan.
            <br />
            <br />
            As for “Shami Eye Center”, it was established in 2008 under the
            patronage of her royal highness Princess Sumaya Bint Al-Hassan.
            Shami Eye Center operates in three locations around the Kingdom and
            three other locations in the Middle East. Collectively, our clinics
            are run by 16 ophthalmologists, 17 optometrists and 145 other
            healthcare professionals, administrative and support staff.
            <br />
            <br />
            Shami Brand is well reputed for over half a century, for providing
            premium quality and accuracy in ophthalmic care. Our continued
            success and achievements are a tribute to our exceptional team who
            deliver excellent, high quality, personalized and compassionate care
            for each of our patients. While we are honored to be a prominent
            health care provider, it’s really our patients who are our top
            priority.
          </p></v-card
        >
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>
        <v-card outlined class="transparent"
          ><h2 class="boldm titleam" style="color: #2a9ae5; margin: 0">
            Who We Are?
          </h2></v-card
        >
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170"
          ><p style="font-size: 4.266666666666667vw">
            We were established in 2008, and were officially inaugurated in
            Amman, Jordan under the royal patronage of HRH Princess Sumaya Bint
            El Hassan on the first of June, 2008.
            <br />
            <br />
            We are owned by the family of the well-established Optikos Shami in
            the field of optics. Optikos Shami opened its first branch in 1960,
            in the city of Irbid in northern Jordan; in 2011, the number of
            branches reached 38, serving customers all over Amman, Al Zarqa,
            Irbid and Aqaba.
            <br />
            <br />
            We are committed to using state-of-the-art technology to offer
            premium, quality care to our patients. And by combining modern
            technology with an expert staff, we were able to become one of the
            largest specialized centers in both the Kingdom and the region.
          </p></v-card
        >
        <v-card outlined tile class="transparent" height="1.875vw"></v-card>

        <v-card outlined class="transparent"
          ><h3 class="subtitlemm" style="color: #2a3977">
            They currently include:
          </h3>
        </v-card>
        <v-card outlined tile class="transparent" height="2.1875vw"></v-card>
        <v-card outlined class="transparent">
          <span v-for="(item, i) in pics" :key="i">
            <v-card outlined class="transparent" style="display: flex">
              <v-card outlined class="transparent">
                <v-img height="13.36vw" width="13.36vw" :src="item.img"></v-img>
              </v-card>
              <v-card
                outlined
                class="transparent"
                style="padding: 1.484375vw 0 0 2.328125vw"
              >
                <p
                  v-html="item.titlem"
                  style="color: #2a9ae5; font-size: 4.266666666666667vw"
                ></p>
              </v-card>
            </v-card>
            <v-card outlined class="transparent" height="1.5234375vw"></v-card>
          </span>
        </v-card>
        <v-card outlined tile class="transparent" height="1.328125vw"></v-card>
        <h2 class="boldm titleam" style="color: #2a9ae5; margin: 0">
          Medical Tourism in Jordan
        </h2>
        <v-card outlined tile class="transparent" height="1.484375vw"></v-card>
        <v-card outlined class="transparent" style="color: #204170">
          <p style="font-size: 4.266666666666667vw">
            Medical tourism in Jordan is ranked by the World Bank report in 2014
            as the first in the Middle East and North Africa and the fifth
            worldwide.
            <br />
            <br />
            Jordan is the only country in the Middle East that generates more
            income than its expenditure in the healthcare industry, and it is
            proudly on the fast track to the top in the healthcare field Jordan
            also possesses natural and cultural attractions for tourists, which
            adds to its advantage as a medical tourism location, as it embraces
            “Petra” one of the recently crowned Seven Wonders of the World.
            Jordan is also home to the Dead Sea, the lowest point on earth,
            which is considered one of the best natural spas in the world.
            <br />
            <br />
            The centers of excellence in eye care in Jordan, like Shami Eye,
            ensure the provision of high quality standards in healthcare
            services that implement certain core values; quality, accessibility,
            availability and affordability.
          </p>
        </v-card>
        <v-card outlined tile class="transparent" height="4.6875vw"></v-card>
      </div>
    </div>
  </div>
</template>

<script>
import photo from "../assets/aboutus/mask_group_2.png";
import mphoto from "../assets/aboutus/Group 451.png";
import c1 from "../assets/aboutus/Group 186.png";
import c2 from "../assets/aboutus/Group 187.png";
import c3 from "../assets/aboutus/Group 188.png";
import c4 from "../assets/aboutus/Group 189.png";
import c5 from "../assets/aboutus/Group 190.png";
import c6 from "../assets/aboutus/Group 191.png";
import c7 from "../assets/aboutus/Group 192.png";
import c8 from "../assets/aboutus/Group 193.png";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Eye | About Us",
    meta: [
      {
        name: "description",
        content:
          "Our story began in 1960, the year which signaled the start of our work as health care providers. Since then the well-established “Optikos Shami” grew and became spread over 40 branches inside and outside Jordan.",
      },
    ],
    // all titles will be injected into this template
  },
  data() {
    return {
      photo,
      mphoto,
      pics: [
        {
          img: c1,

          title: "Post Operation Unit",
          titlem: "Post Operation Unit",
        },
        {
          img: c2,

          title: "Pediatrics Ophthalmology Unit",
          titlem: "Pediatrics Ophthalmology Unit",
        },
        {
          img: c3,

          title: "Refractive Errors Correction Unit",
          titlem: "Refractive Errors <br> Correction Unit",
        },
        {
          img: c4,

          title: "Oculoplasty and Beauty Unit",
          titlem: "Oculoplasty and Beauty Unit",
        },
        {
          img: c5,

          title: "Cataract Operations Unit",
          titlem: "Cataract Operations Unit",
        },
        {
          img: c6,

          title: "Retina Unit",
          titlem: "Retina Unit",
        },
        {
          img: c7,

          title: "Cornea Unit",
          titlem: "Cornea Unit",
        },
        {
          img: c8,

          title: "Glaucoma and Eye Pressure Unit",
          titlem: "Glaucoma and Eye <br> Pressure Unit",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
